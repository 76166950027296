//React
import { useContext, useState } from "react";
//Componentes externos
import { Form } from "react-bootstrap";
//Componentes
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { Loading } from "../../components/Loading/Loading";
//Hooks externos
import { useForm } from "react-hook-form";
//Hooks
import { useMutationData } from "../../hooks/useMutationData";
//Contexts
import { AuthContext } from "../../context/AuthContext";
//Estilos
const classes = require("./LoginScreen.module.css").default;

/**
 * Screen Login
 * @description: Pantalla para iniciar sesión.
 * @date 01/10/2023.
 * @returns JSX del Screen.
 */
export const LoginScreen = () => {
  /* ----------------------------- useState's -------------------------------*/
  const [password, setPassword] = useState<boolean>(true);
  /* ----------------------------- Hooks ------------------------------------*/
  //Hook del formulario
  const { control, handleSubmit } = useForm();
  //Hook para realizar la petición
  const mutateLogin = useMutationData({ url: "/login" });
  //Hooks para obtener el context
  const authContext = useContext(AuthContext);
  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (mutateLogin.isPending) {
    return <Loading />;
  }
  //Return principal
  return (
    <div className={classes.maincontainer}>
      <div className={classes.main}>
        {/* Logo */}
        <div className={classes.div}>
          <img
            className={classes.cogs}
            src={require("../../img/CIO.png")}
            alt="CIO"
            width={"130"}
          />
        </div>
        {/* Título */}
        <label className={classes.label}>Sistema de Control de Reacciones</label>
        {/* Formulario */}
        <Form>
          <div className="row">
            <div className="col-8 offset-2">
              {/* Alias */}
              <CustomInput
                control={control}
                name="correo"
                placeholder="Correo"
                rules={{
                  required: "Se requiere de un correo",
                  maxLength: {
                    value: 32,
                    message: "El correo tiene que ser menos a 32 caracteres",
                  },
                }}
              />
              {/* Contraseña */}
              <CustomInput
                control={control}
                name="contrasena"
                placeholder="Contraseña"
                rules={{
                  required: "Se requiere de una contraseña",
                }}
                tipo={password ? "password" : "text"}
              />
            </div>
            <div className="form-check col-md-6 col-sm-12 offset-3 mt-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                onClick={() => setPassword(!password)}
              />
              <label className="form-check-label text-light" htmlFor="flexCheckDefault">
                Mostrar contraseña
              </label>
            </div>
          </div>
          {/* Botón que envía las credenciales */}
          <button
            className={classes.button}
            onClick={handleSubmit((data: any) => {
              mutateLogin.mutate({ data: data, tipo: "post" }, {
                onSuccess: (data) => {
                  authContext.login(
                    data.data.data.usuario,
                    data.data.data.token,
                    data.data.data.rol
                  );
                }
              });
            })}
          >
            Iniciar sesión
          </button>
        </Form>
      </div>
    </div>
  );
};
