//Componentes externos
import { Card } from "react-bootstrap";

/**
 * AvisoPrivacidadScreen
 * @description: Pantalla que muestra el avisode privacidad
 * @date 09/11/2023.
 * @returns JSX del Screen.
 */
export const AvisoPrivacidadScreen = () => {
  //Return principal
  return (
    <div className="p-4">
      <Card>
        <Card.Body>
          <div className="mb-3">
            <img src={require("../../img/skyangel.png")} width="100" height="100" alt="SkyOne" />
          </div>
          <div className="container">
            <div className="row mb-3">
              <div className="col-md-12 text-center">
                <div className="mb-3"></div>
                <h1 className="display-4 text-center" data-fx="flipInX">
                  Aviso de Privacidad <strong>SkyReaccion</strong>
                </h1>
              </div>
            </div>
            <div className="row mb-3">
              <div className="text-left">
                <h3 className="mb-3">
                  Skyangel<sup>®</sup> MX | SKA TRACKING AND SECURITY S.A. DE
                  C.V.
                </h3>
                <p className="text-primary mb-3">
                  Se informa al usuario que cualquier tratamiento de datos
                  personales dentro de la aplicación móvil{" "}
                  <strong>SkyReaccion</strong>, quedará bajo el ámbito de
                  aplicación de la legislación vigente en México en materia de
                  protección de datos, establecida por la LFPDPPP y su
                  normativa.
                </p>

                <p className="mb-3">
                  Con fundamento en los artículos 16,17,27,28 y demás relativos
                  de la Ley Federal de Protección de Datos Personales en
                  Posesión de Particulares hacemos de su conocimiento que la
                  empresa SKA TRACKING AND SECURITY®, S.A. DE C.V. con domicilio
                  en Río Danubio No. 87, Col. Cuauhtémoc, C.P. 06500, Alcaldía
                  Cuauhtémoc, Ciudad de México, es responsable de recabar sus
                  datos personales, del uso que se le dé a los mismos y de su
                  protección.
                </p>

                <p className="mb-3">&nbsp; </p>

                <p className="mb-3">
                  <strong>
                    Su información personal será utilizada para las siguientes
                    finalidades:
                  </strong>
                </p>

                <ol className="mb-3">
                  <li className="mb-2">
                    La habilidad de poder dar de alta nuevos eventos, así como
                    editarlos. Todo esto de aquellas reacciones que se den de
                    alta en el sistema.
                  </li>
                  <li className="mb-2">
                    La habilidad de poder dar de alta nuevas reacciones, así
                    como editarlas.
                  </li>
                  <li className="mb-2">
                    La capacidad de poder dar seguimiento así como subir audios
                    y fotos para la evidencia de las reacciones.
                  </li>
                </ol>

                <p className="mb-3">
                  <strong>
                    Para las finalidades antes mencionadas, requerimos obtener
                    los siguientes datos personales:
                  </strong>
                </p>
                <ol className="mb-3">
                  <li className="mb-2">
                    Su información personal como su nombre completo, correo
                    electrónico y su puesto dentro de la empresa SkyAngel, la
                    cual se obtendrá a través de la su cuenta creada previamente
                    en{" "}
                    <a href="https://reaccion.skyangel.com.mx//">
                      https://reaccion.skyangel.com.mx//
                    </a>{" "}
                  </li>
                </ol>

                <p className="mb-3">
                  <strong>SEGURIDAD DE SUS DATOS PERSONALES:</strong>
                </p>

                <p className="mb-3">
                  SKA TRACKING AND SECURITY®, S.A. DE C.V. adopta diversas
                  medidas de seguridad para resguardar los datos personales,
                  tales como: restringir el acceso a sus bases de datos, tanto
                  físicas, como electrónicas; establecer compromisos de
                  confidencialidad respecto de toda la información a la que se
                  tiene acceso por parte de las personas que prestan servicios a
                  SKA TRACKING AND SECURITY®, S.A. DE C.V. con motivo de sus
                  funciones; firma de convenios de confidencialidad con los
                  proveedores de La Responsable; resguardar archivos bajo llave
                  con acceso restringido; así como celebrar contratos y acuerdos
                  de confidencialidad y responsabilidad con terceros que
                  reciben, en su caso, datos personales con motivo de cesiones y
                  transferencias necesarias o relacionadas con las finalidades
                  señaladas en este Aviso de Privacidad. Además, se realizan
                  campañas de actualización de datos a efecto de depurar las
                  bases de datos y respetar el principio de calidad reflejado en
                  la Ley Federal de Protección de Datos Personales en Posesión
                  de los Particulares.
                </p>

                <p className="mb-3">
                  <strong>
                    EJERCICIO DE LOS DERECHOS DE ACCESO, RECTIFICACIÓN,
                    CANCELACIÓN Y OPOSICIÓN. LIMITACIÓN AL USO O DIVULGACIÓN DE
                    LOS DATOS Y REVOCACIÓN DEL CONSENTIMIENTO.
                  </strong>
                </p>

                <p className="mb-3">
                  El usurario tiene el derecho de acceder a sus datos personales
                  en posesión de SKA TRACKING AND SECURITY®, S.A. DE C.V y a
                  conocer las características del tratamiento que se da a los
                  mismos; a rectificarlos en caso de ser inexactos, atrasados o
                  incompletos; solicitar sean cancelados cuando considere que
                  son innecesarios para las finalidades para las cuales se
                  recabaron y que no están siendo tratados conforme a los
                  principios y deberes que establece la LFPDPPP, así como a
                  oponerse a su tratamiento para fines específicos. De igual
                  forma, el usuario podrá solicitar se limite el uso o
                  divulgación de sus datos personales, así como revocar el
                  consentimiento que en su caso haya otorgado.
                </p>

                <p className="mb-3">
                  Asimismo, el usuario podrá dirigir su oposición mediante la
                  remisión de un correo electrónico a la dirección:{" "}
                  <a href="mailto:atencion.clientes@skyangel.com.mx">
                    atencion.clientes@skyangel.com.mx
                  </a>
                  , con el asunto "OPOSICIÓN", así como derecho a revocar su
                  consentimiento solicitando la baja del servicio de recepción
                  de comunicaciones comerciales por correo electrónico mediante
                  remisión de un correo electrónico a la dirección:{" "}
                  <a href="mailto:atencion.clientes@skyangel.com.mx">
                    atencion.clientes@skyangel.com.mx
                  </a>{" "}
                  con el asunto "BAJA".
                </p>

                <p className="mb-3">
                  SKA TRACKING AND SECURITY<sup>®</sup> informa al usuario que,
                  de conformidad con lo dispuesto en la LFPDPPP y su Reglamento,
                  ha adoptado las medidas de índole técnica, administrativa y
                  físicas necesarias para garantizar la seguridad de los datos
                  de carácter personal y evitar la alteración, pérdida,
                  tratamiento o acceso no autorizado, habida cuenta del estado
                  de la tecnología, la naturaleza de los datos almacenados y los
                  riesgos a que están expuestos, ya provengan de la acción
                  humana o del medio físico o natural y que sólo registrará
                  datos de carácter personal en bases de datos que reúnan las
                  condiciones que se determinan en los mencionados ordenamientos
                  con respecto a su integridad y seguridad y a las de los
                  centros de tratamiento, locales, equipos, sistemas y
                  programas.
                </p>

                <p className="text-primary mb-3">
                  <strong>IMPORTANTE</strong>
                </p>

                <p className="mb-3">
                  Se recomienda al Titular consultar el Aviso de Privacidad, por
                  lo menos semestralmente, para estar actualizado de las
                  condiciones y términos del mismo. De cualquier forma, el mismo
                  se encuentra a su disposición, de forma directa y personal en
                  nuestros canales de atención a clientes, incluyendo cualquier
                  medio electrónico, óptico, sonoro, visual, o a través de
                  cualquier otra tecnología en{" "}
                  <a
                    href="https://reaccion.skyangel.com.mx/aviso_privacidad"
                    title="Aviso de privacidad"
                    target="new"
                  >
                    https://reaccion.skyangel.com.mx/aviso_privacidad
                  </a>
                </p>

                <p className="mb-3">
                  {" "}
                  Si usted considera que su derecho de protección de datos
                  personales ha sido lesionado por alguna conducta de nuestros
                  empleados o de nuestras actuaciones o respuestas, presume que
                  en el tratamiento de sus datos personales existe alguna
                  violación a las disposiciones previstas en la Ley Federal de
                  Protección de Datos Personales en Posesión de los
                  Particulares, podrá interponer la queja o denuncia
                  correspondiente ante el IFAI, para mayor información visite{" "}
                  <a href="http://www.ifai.org.mx">www.ifai.org.mx</a>. De
                  conformidad con lo que establece el artículo 9 de la Ley en
                  cita, requerimos de su consentimiento expreso para el
                  tratamiento de sus datos personales sensibles, por lo que le
                  solicitamos indique si acepta o no el tratamiento:Consiento
                  que mis datos personales sensibles sean tratados conforme a
                  los términos y condiciones del presente aviso de privacidad.{" "}
                </p>

                <p className="mb-0">
                  Fecha de última actualización: 05/10/2023 | Protección de
                  Datos Personales
                </p>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
