//Componentes externos
import { Button, Container, Form } from 'react-bootstrap';
//Componentes
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { Loading } from '../../components/Loading/Loading';
import { CustomSelect } from '../../components/CustomSelect/CustomSelect';
//Hooks externos
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
//Hooks
import { useMutationData } from '../../hooks/useMutationData';
import { useGetData } from '../../hooks/useGetData';
import { ReaccionInterface } from '../../interfaces/ReaccionInterface';
import { SistemaInterface } from '../../interfaces/SistemaInterface';
import { CausaReaccionInterface } from '../../interfaces/CausaReaccionInterface';
//Estilo
const classes = require('./ReaccionEditarScreen.module.css').default;

/**
 * ReaccionEditarScreen
 * @description: Pantalla para editar una reacción
 * @date 09/11/2023.
 * @returns JSX del Screen.
 */
export const ReaccionEditarScreen = () => {
  /* ----------------------------- Hooks ------------------------------------*/
  //Se obtienen el parámetro de la url
  const { reaccion_id } = useParams();
  //Hook del formulario
  const { control, handleSubmit } = useForm();
  //Hook para realizar la petición
  const mutateReaccion = useMutationData({
    url: `/reaccion/${reaccion_id}`,
    reload: ['reaccion', reaccion_id],
  });
  //Hook para obtener el registro
  const queryReaccion = useGetData<ReaccionInterface>({
    url: `/reaccion/${reaccion_id}?includeAll=true`,
    name: ['reaccion', `${reaccion_id}`],
  });
  //Hook para obtener el registro
  const querySistema = useGetData<SistemaInterface[]>({
    url: `/sistema`,
    name: ['sistema'],
  });
  //Hook para obtener el registro
  const queryCausaReaccion = useGetData<CausaReaccionInterface[]>({
    url: `/causa_reaccion?estatus[eq]=1`,
    name: ['causa_reaccion'],
  });
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (
    querySistema.isLoading ||
    queryReaccion.isLoading ||
    queryCausaReaccion.isLoading ||
    mutateReaccion.isPending
  ) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container className="p-4">
      <Form>
        <div className="row">
          {/* Subtítulo */}
          <div className="row mt-3">
            <h2 className={classes.subtitulo}>Reacción </h2>
          </div>
          {/* Causa reaccion */}
          <CustomSelect
            control={control}
            name="reaccion.causa_reaccion_id"
            label="Causa de la reacción"
            valueField={'id'}
            labelField={'nombre'}
            defaultValue={queryReaccion.data?.causa_reaccion?.id}
            data={queryCausaReaccion.data}
            rules={{
              required: 'Se requiere elegir una causa de reacción',
            }}
          />
          {/* Lugar reacción */}
          <CustomInput
            control={control}
            name="reaccion.lugar_reaccion"
            label="Lugar de reacción"
            value={queryReaccion.data?.lugar_reaccion}
            rules={{
              maxLength: {
                value: 256,
                message:
                  'El lugar de reacción tiene que ser menor a 256 caracteres',
              },
            }}
          />
          {/* Coordenadas reacción */}
          <CustomInput
            control={control}
            name="reaccion.coordenadas_reaccion"
            label="Coordenadas de reacción"
            value={`${queryReaccion.data?.latitud}, ${queryReaccion.data?.longitud}`}
            rules={{
              required: 'Se requiere de unas coordenadas de reacción',
              pattern: {
                value:
                  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
                message:
                  'Las coordenadas de la reacción no tienen un formato correcto',
              },
            }}
          />
          {/* Estatus reacción */}
          <CustomInput
            control={control}
            name="reaccion.estatus_reaccion_id"
            value={queryReaccion.data?.estatus_reaccion?.id.toString()}
            tipo="hidden"
            isDisabled
          />
        </div>

        <div className="row">
          {/* Subtítulo */}
          <div className="row mt-3">
            <h2 className={classes.subtitulo}>Movimiento</h2>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 px-4">
              {/* Marca */}
              <CustomInput
                control={control}
                name="movimiento.marca"
                label="Marca"
                value={queryReaccion.data?.movimiento?.marca}
                rules={{
                  maxLength: {
                    value: 32,
                    message: 'La marca tiene que ser menor a 32 caracteres',
                  },
                }}
              />
              {/* Placa */}
              <CustomInput
                control={control}
                name="movimiento.placa"
                label="Placa"
                value={queryReaccion.data?.movimiento?.placa}
                rules={{
                  maxLength: {
                    value: 16,
                    message: 'La placa tiene que ser menor a 16 caracteres',
                  },
                }}
              />
              {/* Modelo */}
              <CustomInput
                control={control}
                name="movimiento.modelo"
                label="Modelo"
                tipo="number"
                value={queryReaccion.data?.movimiento?.modelo?.toString()}
                rules={{
                  min: {
                    value: 0,
                    message: 'El modelo tiene que ser positivo',
                  },
                }}
              />
              {/* Color */}
              <CustomInput
                control={control}
                name="movimiento.color"
                label="Color"
                value={queryReaccion.data?.movimiento?.color}
                rules={{
                  maxLength: {
                    value: 128,
                    message: 'El color tiene que ser menor a 128 caracteres',
                  },
                }}
              />
              {/* Económico */}
              <CustomInput
                control={control}
                name="movimiento.economico"
                label="Económico"
                value={queryReaccion.data?.movimiento?.economico}
                rules={{
                  maxLength: {
                    value: 32,
                    message: 'El económico tiene que ser menor a 32 caracteres',
                  },
                }}
              />
              {/* Caja */}
              <CustomInput
                control={control}
                name="movimiento.caja"
                label="Caja"
                value={queryReaccion.data?.movimiento?.caja}
                rules={{
                  maxLength: {
                    value: 128,
                    message: 'La caja tiene que ser menor a 128 caracteres',
                  },
                }}
              />
              {/* Contenedor 1 */}
              <CustomInput
                control={control}
                name="movimiento.contenedor1"
                label="Contenedor 1"
                value={queryReaccion.data?.movimiento?.contenedor1}
                rules={{
                  maxLength: {
                    value: 128,
                    message:
                      'El contenedor tiene que ser menor a 128 caracteres',
                  },
                }}
              />
              {/* Contenedor 2 */}
              <CustomInput
                control={control}
                name="movimiento.contenedor2"
                label="Contenedor 2"
                value={queryReaccion.data?.movimiento?.contenedor2}
                rules={{
                  maxLength: {
                    value: 128,
                    message:
                      'El contenedor tiene que ser menor a 128 caracteres',
                  },
                }}
              />
            </div>
            <div className="col-md-6 col-sm-12 px-4">
              {/* Operador */}
              <CustomInput
                control={control}
                name="movimiento.operador"
                label="Operador"
                value={queryReaccion.data?.movimiento?.operador}
                rules={{
                  maxLength: {
                    value: 128,
                    message: 'El operador tiene que ser menor a 128 caracteres',
                  },
                }}
              />
              {/* Medio */}
              <CustomInput
                control={control}
                name="movimiento.medio"
                label="Medio"
                value={queryReaccion.data?.movimiento?.medio}
                rules={{
                  maxLength: {
                    value: 32,
                    message: 'El medio tiene que ser menor a 32 caracteres',
                  },
                }}
              />
              {/* Origen */}
              <CustomInput
                control={control}
                name="movimiento.origen"
                label="Origen"
                value={queryReaccion.data?.movimiento?.origen}
                rules={{
                  maxLength: {
                    value: 256,
                    message: 'El origen tiene que ser menor a 256 caracteres',
                  },
                }}
              />
              {/* Destino */}
              <CustomInput
                control={control}
                name="movimiento.destino"
                label="Destino"
                value={queryReaccion.data?.movimiento?.destino}
                rules={{
                  maxLength: {
                    value: 256,
                    message: 'El operador tiene que ser menor a 256 caracteres',
                  },
                }}
              />
              {/* Transportista */}
              <CustomInput
                control={control}
                name="movimiento.transportista"
                label="Transportista"
                value={queryReaccion.data?.movimiento?.transportista}
                rules={{
                  required: 'Se requiere de un transportista',
                  maxLength: {
                    value: 256,
                    message:
                      'El transportista tiene que ser menor a 256 caracteres',
                  },
                }}
              />
              {/* Plataforma/Central */}
              <CustomInput
                control={control}
                name="movimiento.plataforma"
                label="Central"
                value={queryReaccion.data?.movimiento?.plataforma}
                rules={{
                  required: 'Se requiere de una central',
                  maxLength: {
                    value: 32,
                    message: 'La central tiene que ser menor a 32 caracteres',
                  },
                }}
              />
              {/* Estatus de carga */}
              <CustomSelect
                control={control}
                name="movimiento.estatus_carga"
                label="Estatus de carga"
                labelField={'label'}
                valueField={'value'}
                returnFormat={'label'}
                defaultValue={queryReaccion.data?.movimiento?.estatus_carga}
                data={[
                  { value: 1, label: 'Sin carga' },
                  { value: 2, label: 'Con carga' },
                ]}
                rules={{
                  required: 'Se requiere de un estatus de carga',
                }}
              />
              {/* Sistema */}
              <CustomSelect
                control={control}
                name="movimiento.sistema_id"
                label="Sistema"
                valueField={'id'}
                labelField={'nombre'}
                data={querySistema.data}
                defaultValue={queryReaccion.data?.movimiento?.sistema?.id}
                rules={{
                  required:
                    'Se requiere elegir un sistema de origen de la reacción',
                }}
              />
            </div>
          </div>
        </div>
        <Button
          className={`${classes.boton} mt-3`}
          onClick={handleSubmit((data: any) => {
            mutateReaccion.mutate(
              {
                data: data,
                tipo: 'patch',
              },
              {
                onSuccess: () => {
                  //Se redirige en caso de exito
                  navigate(-1);
                },
              }
            );
          })}
        >
          Guardar
        </Button>
      </Form>
    </Container>
  );
};
