//Hooks externos
import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
//Estilos
const classes = require('./CustomTextArea.module.css').default;

interface Props {
  control: ControllerProps['control'];
  name: string;
  placeholder?: string;
  value?: string;
  label?: string;
  rows?: number;
  rules?: {};
}

export const CustomTextArea = ({
  control,
  name,
  placeholder = '',
  value = '',
  label = '',
  rows = 3,
  rules = {},
}: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value === null ? '' : value}
      render={({
        field: { value, onChange, ref, onBlur },
        fieldState: { error },
      }) => (
        <div className="row mt-3">
          {label && (
            <label
              className={`col-3 col-form-label ${classes.labelBackground}`}
              htmlFor={name}
            >
              {label}
            </label>
          )}
          <div
            className={`col-${
              label ? '9 ' + classes.noPadding : '12 ' + classes.noPadding
            }`}
          >
            <textarea
              ref={ref}
              id={name}
              placeholder={placeholder}
              rows={rows}
              className={`form-control ${
                error === undefined ? 'is-valid' : 'is-invalid'
              } `}
              value={value}
              onChange={text => {
                onChange(text);
              }}
              onBlur={onBlur}
            />
            <div className="invalid-feedback">{error?.message || 'Error'}</div>
          </div>
        </div>
      )}
    />
  );
};
