import { useSearchParams } from 'react-router-dom';
import { useMutationData } from './useMutationData';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

export function useLoginInvitado() {
  const [searchParams] = useSearchParams();
  //Hooks para obtener el context
  const authContext = useContext(AuthContext);
  //Hook para realizar la petición
  const mutateLoginTemporal = useMutationData({ url: '/loginTemporal' });

  const loginInvitado = async (
    movimiento_externo_id: string,
    sistema_id: string
  ) => {
    if (!authContext.isLoggedIn) {
      try {
        const data = await mutateLoginTemporal.mutateAsync({ tipo: 'post' });
        authContext.login(
          {
            id: 2,
            usuario_externo_id: searchParams.get('usuario_externo_id') ?? '',
            usuario_externo_nombre:
              searchParams.get('usuario_externo_nombre') ?? '',
          },
          data.data.data.token,
          data.data.data.rol,
          movimiento_externo_id.toString(),
          sistema_id.toString(),
        );
      } catch (error) {
        console.error('Error during login:', error);
        throw error;
      }
    }
  };

  return loginInvitado;
}
