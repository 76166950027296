//React
import { useContext } from 'react';
//Componentes externos
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
//Hooks externos
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
//Hooks
import useWebSocket from './useWebSocket';
//Contexts
import { AuthContext } from '../context/AuthContext';
//API
import { API } from '../global/global';
//Props
interface UseGetDataProps {
  url: string;
  name: string[];
  condition?: boolean;
  hasWebSocket?: boolean;
}

/**
 * useGetData
 * @description: Hook para realizar peticiones get.
 * @date 28/03/2024
 * @param {UseGetDataProps<T>} { url, name, condition, hasWebSocket }
 * @returns JSX del Screen.
 */
export const useGetData = <T,>({
  url,
  name,
  condition = true,
  hasWebSocket = false,
}: UseGetDataProps) => {
  /* ----------------------------- Hooks ------------------------------------*/
  //Context
  const authContext = useContext(AuthContext);
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  //Hooks para el manejo del WebSocket
  useWebSocket({
    channel: name[0],
    eventName: name.join('-'),
    condition: hasWebSocket,
  });
  /* ------------------------------ Funciones -------------------------------*/
  const fetchData = async () => {
    try {
      const response: AxiosResponse<{ data: T | null }> = await axios.get(
        `${API}${url}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authContext.token,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Se hace logout si el token es incorrecto
        if (error.response?.data?.message === 'Unauthenticated.') {
          //Si no está autenticado hacer el logout
          authContext.logout();
          //Se muestra el mensaje de error
          toast.error('Tu sesión ha expirado.', {
            toastId: 'errorMessage',
          });
        } else if (error.response?.status === 403) {
          //Se redirecciona a la página principal cuando no se tiene autorización
          navigate('/');
          toast.error('No tienes permiso de realizar esta acción.', {
            toastId: 'errorMessage',
          });
        } else {
          //Se muestra el mensaje de error
          toast.error(error.response?.data?.message || 'Error', {
            toastId: 'errorMessage',
          });
        }
      }
      //Prevent query from being successful
      throw new Error();
    }
  };
  /* ------------------------------ Return ----------------------------------*/
  return useQuery<T | null>({
    queryKey: name,
    queryFn: fetchData,
    enabled: condition,
  });
};
