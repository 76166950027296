//React
import { useContext } from "react";
//Componentes externos
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
//Hooks
import { useMutationData } from "../../hooks/useMutationData";
//Contexts
import { AuthContext } from "../../context/AuthContext";
//Estilos
const classes = require("./NavbarGeneral.module.css").default;

/**
 * Navbar general
 * @description: Es un componente mostrando el navbar general
 * @date 10/10/2023.
 * @returns JSX del Screen.
 */
export const NavbarGeneral = () => {
  /* ----------------------------- Hooks ------------------------------------*/
  //Hooks para obtener el context
  const authContext = useContext(AuthContext);
  //Hook para realizar la petición
  const mutateLogOut = useMutationData({ url: "/logout" });
  /* -------------------------------- return --------------------------------*/
  return (
    <>
      {/* Navbar */}
      <Navbar expand="lg" className={classes.navbar}>
        <Container fluid>
          <img
            src={require("../../img/CIO.png")}
            alt=""
            width="100"
            className="px-4"
          />
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {/* Se muestran las opciones del menú si ha iniciado sesión */}
          {authContext.isLoggedIn && authContext.rol !== "Invitado" && (
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {authContext.rol === "Administrador" && (
                  <NavDropdown title="Administración">
                    <Link className={classes.dropdownItem} to="/causa_reaccion">
                      Causas de reacción
                    </Link>
                    <Link className={classes.dropdownItem} to="/rol">
                      Rol
                    </Link>
                    <Link className={classes.dropdownItem} to="/usuario">
                      Usuario
                    </Link>
                  </NavDropdown>
                )}
                <NavDropdown title="Reacciones">
                  <Link
                    className={classes.dropdownItem}
                    to="/reaccion/lista/1"
                  >
                    Creadas
                  </Link>
                  <Link
                    className={classes.dropdownItem}
                    to="/reaccion/lista/2"
                  >
                    Concluidas
                  </Link>
                </NavDropdown>
              </Nav>
              <button
                className={classes.cerrarsesion}
                onClick={async () => {
                  await mutateLogOut.mutateAsync({ tipo: "post" });
                  authContext.logout();
                }}
              >
                Cerrar sesión
              </button>
            </Navbar.Collapse>
          )}
        </Container>
      </Navbar>
      <img src={require("../../img/IMG_FONDO_MOTO.png")} width="100%" alt="" />
    </>
  );
};
