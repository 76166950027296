//Componentes externos
import moment from 'moment';
import 'moment/locale/es';

/**
 * getFechaFormateada
 * @description: Función devuelve la fecha formateada.
 * @date 15/12/2023
 * @param {Date | undefined} fecha
 * @returns {String}
 */
export const getFechaFormateada = (fecha: Date | undefined | null) => {
  moment.locale('es');
  const momentFecha = moment(fecha);
  return momentFecha.format('DD/MM/YYYY H:mm:ss');
};
