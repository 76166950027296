//React
import { Fragment, useState } from "react";
//Componentes externos
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
  getSortedRowModel,
  SortingState,
  getExpandedRowModel,
  Row,
} from "@tanstack/react-table";
import { Form, Pagination, Table } from "react-bootstrap";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Estilos
const classes = require("./CustomTable.module.css").default;

/**
 * CustomTable Component
 * @description: Dibuja una tabla para paginarla, ordenarla y filtrarla.
 * @date 26/05/2023.
 * @param Props Recibe data (los datos de la tabla) y columns que es el formato de las columnas.
 * @returns JSX de la tabla.
 */
const CustomTable = ({
  data,
  columns,
  expandable,
  renderSubComponent,
}: {
  data: any[] | null | undefined;
  columns: ColumnDef<any>[];
  expandable?: (row: Row<any>) => boolean;
  renderSubComponent?: (props: { row: Row<any> }) => React.ReactElement;
}) => {
  /* ----------------------------- useState's -------------------------------*/
  const [sorting, setSorting] = useState<SortingState>([]);
  /* ----------------------------- Hooks ------------------------------------*/
  const table = useReactTable({
    data: data ?? [],
    columns,
    state: {
      sorting,
    },
    // Pipeline
    getRowCanExpand: expandable,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //modo de ejeucicon (debug o produccion)
    debugTable: false,
  });
  /* -------------------------------- return --------------------------------*/
  //Return princiapl
  return (
    <div className="card shadow-2-strong">
      <div className="table-responsive">
        <Table hover className="rounded rounded-3 overflow-hidden">
          {/* Headers */}
          <thead className={classes.cabecera}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={classes.alignMiddle}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={
                        (header.id !== "expander")
                          ? classes.columna
                          : null
                      }
                    >
                      {header.isPlaceholder ? null : (
                        <div>
                          <button
                            type="button"
                            className={`btn btn-link ${classes.titulo}`}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            <span>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: <FontAwesomeIcon icon={faArrowUp} />,
                                desc: <FontAwesomeIcon icon={faArrowDown} />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </span>
                          </button>
                          {/**Inputs para el filtrado de las columnas  */}
                          <div>
                            {header.column.getCanFilter() ? (
                              <div>
                                <Form.Control
                                  type="text"
                                  value={
                                    (header.column.getFilterValue() ??
                                      "") as string
                                  }
                                  onChange={(e) =>
                                    header.column.setFilterValue(e.target.value)
                                  }
                                  placeholder={`Buscar...`}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {/* Cuerpo */}
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Fragment key={row.id}>
                  <tr key={row.id}>
                    {/** Renderiza la info de cada renglon */}
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={`${row.id} ${cell.id}`}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  {row.getIsExpanded() && (
                    <tr>
                      {/* 2nd row is a custom 1 cell row */}
                      <td colSpan={row.getVisibleCells().length}>
                        {renderSubComponent ? renderSubComponent({ row }) : ""}
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </Table>

        {/** Coloca los inputs de la paginacion */}
        <div className="d-flex flex-row justify-content-around">
          <Pagination>
            <Pagination.First
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            />
            <Pagination.Prev
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            />
            <Pagination.Next
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            />
            <Pagination.Last
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            />
          </Pagination>
          <div className="d-flex flex-row align-items-center">
            <p>
              Página {table.getState().pagination.pageIndex + 1} de{" "}
              {table.getPageCount()}
            </p>
          </div>
          <Form.Select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            className="w-25 h-50"
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
