//React
import { useMemo } from 'react';
//Componentes externos
import { Button, Container } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import {
  faTrash,
  faRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//Componentes
import CustomTable from '../../components/CustomTable/CustomTable';
import { Loading } from '../../components/Loading/Loading';
//Hooks
import { useMutationData } from '../../hooks/useMutationData';
import { useGetData } from '../../hooks/useGetData';
//Interfaces
import { RolInterface } from '../../interfaces/RolInterface';
//Estilo
const classes = require('./RolListaScreen.module.css').default;

/**
 * RolListaScreen
 * @description: Pantalla que muestra la lista de roles
 * @date 16/10/2023.
 * @returns JSX del Screen.
 */
export const RolListaScreen = () => {
  /* ----------------------------- Hooks ------------------------------------*/
  //Hook para cambiar el estatus del registro
  const mutateRol = useMutationData({
    url: '/rol',
    reload: ['rol'],
  });
  //Se encarga de traer la información
  const queryRol = useGetData<RolInterface[]>({ url: '/rol', name: ['rol'] });
  /* ------------------------------ Funciones -------------------------------*/
  const columns = useMemo<ColumnDef<RolInterface>[]>(
    () => [
      {
        header: '#',
        accessorKey: 'id',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'nombre',
        header: () => 'Nombre',
      },
      {
        accessorKey: 'descripcion',
        header: () => 'Descripción',
      },
      {
        id: 'estatus',
        header: 'Estatus',
        accessorFn: row => (row.estatus === 1 ? 'ACTIVO' : 'ELIMINADO'),
        cell: info => info.getValue(),
      },
      {
        header: 'Acciones',
        cell: info => (
          <fieldset className="btn-group" role="group" aria-label="Ejemplo">
            {/** Botón para reactivar*/}
            {info.row.original.estatus === 0 && (
              <Button
                title="Reactivar rol"
                className={classes.boton}
                onClick={() =>
                  mutateRol.mutate({
                    id: info.row.original.id,
                    data: { estatus: 1 },
                    tipo: 'patch',
                  })
                }
              >
                <FontAwesomeIcon icon={faRotateLeft} />
              </Button>
            )}
            {/** Botón para eliminar*/}
            {info.row.original.estatus === 1 && (
              <Button
                title="Eliminar rol"
                className={classes.boton}
                onClick={() =>
                  mutateRol.mutate({
                    id: info.row.original.id,
                    data: { estatus: 0 },
                    tipo: 'delete',
                  })
                }
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </fieldset>
        ),
      },
    ],
    [mutateRol]
  );
  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (queryRol.isLoading) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container className="p-4">
      {/* Subtítulo */}
      <div className="row mt-3">
        <h2 className={classes.subtitulo}>Roles</h2>
      </div>
      <CustomTable
        {...{
          data: queryRol.data,
          columns,
        }}
      />
    </Container>
  );
};
