//Componentes externos
import { Button, Container, Form } from "react-bootstrap";
//Componentes
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { Loading } from "../../components/Loading/Loading";
//Hooks externos
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
//Hooks
import { useMutationData } from "../../hooks/useMutationData";
import { CausaReaccionInterface } from "../../interfaces/CausaReaccionInterface";
import { useGetData } from "../../hooks/useGetData";
//Estilo
const classes = require("./CausaReaccionEditarScreen.module.css").default;

/**
 * CausaReaccionEditarScreen
 * @description: Pantalla para crear un causa reaccion
 * @date 16/07/2023.
 * @returns JSX del Screen.
 */
export const CausaReaccionEditarScreen = () => {
  /* ----------------------------- Hooks ------------------------------------*/
  //Hook del formulario
  const { control, handleSubmit } = useForm();
  const { causa_reaccion_id } = useParams();
  //Hook para realizar la petición
  const mutateCausaReaccion = useMutationData({
    url: `/causa_reaccion/${causa_reaccion_id}`,
    reload: ["causa_reaccion", `${causa_reaccion_id}`],
  });
  //Hook para obtener el registro
  const queryCausaReaccion = useGetData<CausaReaccionInterface>({
    url: `/causa_reaccion/${causa_reaccion_id}`,
    name: ["causa_reaccion", `${causa_reaccion_id}`],
  });
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (queryCausaReaccion.isLoading || mutateCausaReaccion.isPending) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container className="my-4">
      <Form>
        <div className="row">
          <div className="col-12">
            {/* Nombre */}
            <CustomInput
              control={control}
              name="nombre"
              label="Nombre"
              value={queryCausaReaccion.data?.nombre}
              rules={{
                required: "Se requiere de un nombre",
                maxLength: {
                  value: 150,
                  message: "El nombre tiene que ser menor a 150 caracteres",
                },
              }}
            />
          </div>
        </div>
        <Button
          className={`${classes.boton} mt-3`}
          onClick={handleSubmit((data: any) => {
            mutateCausaReaccion.mutate(
              {
                data: data,
                tipo: "patch",
              },
              {
                onSuccess: () => {
                  //Se redirige en caso de exito
                  navigate("/causa_reaccion");
                },
              }
            );
          })}
        >
          Guardar
        </Button>
      </Form>
    </Container>
  );
};
