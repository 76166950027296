//React
import { useContext } from 'react';
//Componentes externos
import { Button, Container, Form } from 'react-bootstrap';
//Componentes
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { CustomTextArea } from '../../components/CustomTextArea/CustomTextArea';
import { Loading } from '../../components/Loading/Loading';
//Hooks externos
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
//Hooks
import { useMutationData } from '../../hooks/useMutationData';
//Contexts
import { AuthContext } from '../../context/AuthContext';
//Estilo
const classes = require('./EventoCrearScreen.module.css').default;

/**
 * EventoCrearScreen
 * @description: Pantalla para crear un evento
 * @date 10/11/2023.
 * @returns JSX del Screen.
 */
export const EventoCrearScreen = () => {
  /* ----------------------------- Parámetros ------------------------------------*/
  const { reaccion_id } = useParams();
  /* ----------------------------- Hooks ------------------------------------*/
  //Hook del formulario
  const { control, handleSubmit } = useForm();
  //Hook para realizar la petición
  const mutateEvento = useMutationData({
    url: '/evento',
    reload: ['evento', 'reaccion', reaccion_id],
  });
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  // Se obtienen los valores del usuario
  const authContext = useContext(AuthContext);
  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (mutateEvento.isPending) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container className="p-4">
      <Form>
        <div className="row">
          <div className="col-12">
            {/* Comentario */}
            <CustomTextArea
              control={control}
              name="comentario"
              label="Comentario"
              rules={{
                required: 'Se requiere de un comentario',
                maxLength: {
                  value: 512,
                  message: 'El comentario tiene que ser menor a 512 caracteres',
                },
              }}
            />
            {/* Coordenadas evento */}
            <CustomInput
              control={control}
              name="coordenadas_evento"
              label="Coordenadas del evento"
              rules={{
                pattern: {
                  value:
                    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
                  message:
                    'Las coordenadas del evento no tienen un formato correcto',
                },
              }}
            />
            {authContext.rol === 'Invitado' && (
              <>
                {/* Usuario externo id */}
                <CustomInput
                  control={control}
                  name="usuario_externo_id"
                  value={authContext.usuario?.usuario_externo_id}
                  tipo={'hidden'}
                />
                {/* Usuario externo nombre */}
                <CustomInput
                  control={control}
                  name="usuario_externo_nombre"
                  value={authContext.usuario?.usuario_externo_nombre}
                  tipo={'hidden'}
                />
              </>
            )}
            {/* Usuario id */}
            <CustomInput
              control={control}
              name="usuario_id"
              value={authContext.usuario?.id?.toString()}
              tipo={'hidden'}
            />
            {/* Reacción id */}
            <CustomInput
              control={control}
              name="reaccion_id"
              value={reaccion_id}
              tipo={'hidden'}
            />
          </div>
        </div>
        <Button
          className={`${classes.boton} mt-3`}
          onClick={handleSubmit((data: any) => {
            mutateEvento.mutate(
              {
                data,
                tipo: 'post',
              },
              {
                onSuccess: () => {
                  //Se redirige en caso de exito
                  navigate(`/evento/lista/${reaccion_id}`);
                },
              }
            );
          })}
        >
          Guardar
        </Button>
      </Form>
    </Container>
  );
};
