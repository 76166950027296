import React, { useState, useCallback } from 'react';
import { UsuarioLoginInterface } from '../interfaces/UsuarioLoginInterface';

type AuthContextProps = {
  token: string | null;
  usuario: UsuarioLoginInterface | null;
  rol: string | null;
  movimientoExternoId?: string;
  sistemaId?: string;
  isLoggedIn: boolean;
  login: (
    usuario: UsuarioLoginInterface | null,
    token: string | null,
    rol: string | null,
    movimiento_externo_id?: string,
    sistema_id?: string
  ) => void;
  logout: () => void;
};

export const AuthContext = React.createContext({} as AuthContextProps);

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('token');

  return storedToken;
};

const retrieveStoredRol = () => {
  const storedRol = localStorage.getItem('rol');

  return storedRol;
};

const retrieveStoredUsuario = () => {
  let storedUsuario: UsuarioLoginInterface | null = null;

  if (localStorage.getItem('usuario')) {
    storedUsuario = JSON.parse(localStorage.getItem('usuario') || '{}');
  }

  return storedUsuario;
};

const retrieveStoredMovimientoExternoId = () => {
  const storedMovimientoExternoId = localStorage.getItem('movimientoExternoId');

  return storedMovimientoExternoId;
};

const retrieveSistemaId = () => {
  const storedSistemaId = localStorage.getItem('sistemaId');

  return storedSistemaId;
};

export const AuthContextProvider = ({ children }: any) => {
  const tokenData = retrieveStoredToken();
  const usuarioData = retrieveStoredUsuario();
  const rolData = retrieveStoredRol();
  const movimientoExternoIdData = retrieveStoredMovimientoExternoId();
  const sistemaIdData = retrieveSistemaId();

  //Lógica de token
  let initialToken: string | null = null;

  if (tokenData) {
    initialToken = tokenData;
  }

  const [token, setToken] = useState<string | null>(initialToken);

  const userIsLoggedIn = !!token;

  //Lógica de token
  let initialRol: string | null = null;

  if (rolData) {
    initialRol = rolData;
  }

  const [rol, setRol] = useState<string | null>(initialRol);

  //Lógica de usuario
  let initialUsuario: UsuarioLoginInterface | null = null;

  if (usuarioData) {
    initialUsuario = usuarioData;
  }

  const [usuario, setUsuario] = useState<UsuarioLoginInterface | null>(
    initialUsuario
  );

  //Movimiento externo id
  let initialMovimientoExternoId: string | undefined = undefined;

  if (movimientoExternoIdData) {
    initialMovimientoExternoId = movimientoExternoIdData;
  }

  const [movimientoExternoId, setMovimientoExternoId] = useState<
    string | undefined
  >(initialMovimientoExternoId);

  //Sistema Id
  let initialSistemaId: string | undefined = undefined;

  if (sistemaIdData) {
    initialSistemaId = sistemaIdData;
  }

  const [sistemaId, setSistemaId] = useState<string | undefined>(
    initialSistemaId
  );

  const logoutHandler = useCallback(() => {
    setToken(null);
    setUsuario(null);
    setRol(null);
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('rol');
    localStorage.removeItem('movimientoExternoId');
    localStorage.removeItem('sistemaId');
  }, []);

  const loginHandler = (
    usuario: UsuarioLoginInterface | null,
    token: string | null,
    rol: string | null,
    movimiento_externo_id?: string,
    sistema_id?: string
  ) => {
    setToken(token);
    if (token !== null) {
      localStorage.setItem('token', token);
    }
    setRol(rol);
    if (rol !== null) {
      localStorage.setItem('rol', rol);
    }
    setUsuario(usuario);
    if (usuario !== null) {
      localStorage.setItem('usuario', JSON.stringify(usuario));
    }
    setMovimientoExternoId(movimiento_externo_id);
    if (movimiento_externo_id !== undefined) {
      localStorage.setItem('movimientoExternoId', movimiento_externo_id);
    }
    setSistemaId(sistema_id);
    if (sistema_id !== undefined) {
      localStorage.setItem('sistemaId', sistema_id);
    }
  };

  const contextValue = {
    token: token,
    rol: rol,
    usuario: usuario,
    movimientoExternoId: movimientoExternoId,
    sistemaId: sistemaId,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
