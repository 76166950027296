//React
import { useContext } from 'react';
//Componentes externos
import { Button, Container, Form } from 'react-bootstrap';
//Componentes
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { Loading } from '../../components/Loading/Loading';
import { CustomSelect } from '../../components/CustomSelect/CustomSelect';
//Hooks externos
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
//Hooks
import { useMutationData } from '../../hooks/useMutationData';
import { useGetData } from '../../hooks/useGetData';
//Context
import { AuthContext } from '../../context/AuthContext';
//Interfaces
import { SistemaInterface } from '../../interfaces/SistemaInterface';
import { CausaReaccionInterface } from '../../interfaces/CausaReaccionInterface';
import { MovimientoInterface } from '../../interfaces/MovimientoInterface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
//Estilo
const classes = require('./ReaccionCrearInvitadoScreen.module.css').default;

/**
 * ReaccionCrearInvitadoScreen
 * @description: Pantalla para crear una reacción
 * @date 09/11/2023.
 * @returns JSX del Screen.
 */
export const ReaccionCrearInvitadoScreen = () => {
  /* ----------------------------- Hooks ------------------------------------*/
  //Hook que obtiene el context
  const authContext = useContext(AuthContext);
  //Hook para obtener los parámetros de búsqueda de la url
  const [searchParams] = useSearchParams();
  //Hook del formulario
  const { control, handleSubmit } = useForm();
  //Hook para realizar la petición
  const mutateReaccion = useMutationData({
    url: '/reaccion',
    reload: [
      'movimiento',
      authContext.movimientoExternoId,
      'sistema',
      authContext.sistemaId,
    ],
  });
  //Hook para obtener el registro
  const querySistema = useGetData<SistemaInterface[]>({
    url: `/sistema`,
    name: ['sistema'],
  });
  //Hook para obtener el registro
  const queryCausaReaccion = useGetData<CausaReaccionInterface[]>({
    url: `/causa_reaccion?estatus[eq]=1`,
    name: ['causa_reaccion'],
  });
  //Hook para obtener el registro
  const queryMovimiento = useGetData<MovimientoInterface>({
    url: `/movimiento/${searchParams.get('movimiento_id')}`,
    name: ['movimiento', `${searchParams.get('movimiento_id')}`],
    condition: searchParams.get('movimiento_id') !== null,
  });
  //Hook transforma coordenadas a dirección
  const queryMaps = useQuery({
    queryKey: [
      'geocode',
      searchParams.get('latitud'),
      searchParams.get('longitud'),
    ],
    queryFn: async () => {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${searchParams.get(
          'latitud'
        )},${searchParams.get(
          'longitud'
        )}&key=AIzaSyBq__Lx75xdg25fIOmp652_Qa_oyjQIwv4`
      );
      return response.data.results[0].formatted_address;
    },
    enabled:
      searchParams.get('latitud') !== '' && searchParams.get('longitud') !== '',
  });
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (
    mutateReaccion.isPending ||
    querySistema.isLoading ||
    queryCausaReaccion.isLoading ||
    queryMovimiento.isLoading ||
    queryMaps.isLoading
  ) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container className="p-4">
      <Form>
        <div className="row">
          {/* Subtítulo */}
          <div className="row mt-3">
            <h2 className={classes.subtitulo}>Reacción</h2>
          </div>
          {/* Causa reaccion */}
          <CustomSelect
            control={control}
            name="reaccion.causa_reaccion_id"
            label="Causa de la reacción"
            valueField={'id'}
            labelField={'nombre'}
            data={queryCausaReaccion.data}
            rules={{
              required: 'Se requiere elegir una causa de reacción',
            }}
          />
          {/* Lugar reacción */}
          <CustomInput
            control={control}
            name="reaccion.lugar_reaccion"
            label="Lugar de reacción"
            value={queryMaps.data}
            rules={{
              maxLength: {
                value: 256,
                message:
                  'El lugar de reacción tiene que ser menor a 256 caracteres',
              },
            }}
          />
          {/* Coordenadas reacción */}
          <CustomInput
            control={control}
            name="reaccion.coordenadas_reaccion"
            label="Coordenadas de reacción"
            value={
              searchParams.get('latitud') && searchParams.get('longitud')
                ? `${searchParams.get('latitud')}, ${searchParams.get(
                    'longitud'
                  )}`
                : ''
            }
            rules={{
              required: 'Se requiere de unas coordenadas de reacción',
              pattern: {
                value:
                  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
                message:
                  'Las coordenadas de la reacción no tienen un formato correcto',
              },
            }}
          />
        </div>
        <div className="row">
          {/* Subtítulo */}
          <div className="row mt-3">
            <h2 className={classes.subtitulo}>Movimiento</h2>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 px-4">
              {/* ID */}
              {searchParams.get('movimiento_id') !== null && (
                <CustomInput
                  control={control}
                  name="movimiento.id"
                  value={queryMovimiento.data?.id?.toString()}
                  tipo={'hidden'}
                />
              )}
              {/* Marca */}
              <CustomInput
                control={control}
                name="movimiento.marca"
                label="Marca"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('marca')
                    : queryMovimiento.data?.marca
                }
                rules={{
                  maxLength: {
                    value: 32,
                    message: 'La marca tiene que ser menor a 32 caracteres',
                  },
                }}
              />
              {/* Placa */}
              <CustomInput
                control={control}
                name="movimiento.placa"
                label="Placa"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('placa')
                    : queryMovimiento.data?.placa
                }
                rules={{
                  maxLength: {
                    value: 16,
                    message: 'La placa tiene que ser menor a 16 caracteres',
                  },
                }}
              />
              {/* Modelo */}
              <CustomInput
                control={control}
                name="movimiento.modelo"
                label="Modelo"
                tipo="number"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('modelo')
                    : queryMovimiento.data?.modelo?.toString()
                }
                rules={{
                  min: {
                    value: 0,
                    message: 'El modelo tiene que ser positivo',
                  },
                }}
              />
              {/* Color */}
              <CustomInput
                control={control}
                name="movimiento.color"
                label="Color"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('color')
                    : queryMovimiento.data?.color
                }
                rules={{
                  maxLength: {
                    value: 128,
                    message: 'El color tiene que ser menor a 128 caracteres',
                  },
                }}
              />
              {/* Económico */}
              <CustomInput
                control={control}
                name="movimiento.economico"
                label="Económico"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('economico')
                    : queryMovimiento.data?.economico
                }
                rules={{
                  maxLength: {
                    value: 32,
                    message: 'El económico tiene que ser menor a 32 caracteres',
                  },
                }}
              />
              {/* Caja */}
              <CustomInput
                control={control}
                name="movimiento.caja"
                label="Caja"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('caja')
                    : queryMovimiento.data?.caja
                }
                rules={{
                  maxLength: {
                    value: 128,
                    message: 'La caja tiene que ser menor a 128 caracteres',
                  },
                }}
              />
              {/* Contenedor 1 */}
              <CustomInput
                control={control}
                name="movimiento.contenedor1"
                label="Contenedor 1"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('contenedor1')
                    : queryMovimiento.data?.contenedor1
                }
                rules={{
                  maxLength: {
                    value: 128,
                    message:
                      'El contenedor tiene que ser menor a 128 caracteres',
                  },
                }}
              />
              {/* Contenedor 2 */}
              <CustomInput
                control={control}
                name="movimiento.contenedor2"
                label="Contenedor 2"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('contenedor2')
                    : queryMovimiento.data?.contenedor2
                }
                rules={{
                  maxLength: {
                    value: 128,
                    message:
                      'El contenedor tiene que ser menor a 128 caracteres',
                  },
                }}
              />
            </div>
            <div className="col-md-6 col-sm-12 px-4">
              {/* Operador */}
              <CustomInput
                control={control}
                name="movimiento.operador"
                label="Operador"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('operador')
                    : queryMovimiento.data?.operador
                }
                rules={{
                  maxLength: {
                    value: 128,
                    message: 'El operador tiene que ser menor a 128 caracteres',
                  },
                }}
              />
              {/* Medio */}
              <CustomInput
                control={control}
                name="movimiento.medio"
                label="Medio"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('medio')
                    : queryMovimiento.data?.medio
                }
                rules={{
                  maxLength: {
                    value: 32,
                    message: 'El medio tiene que ser menor a 32 caracteres',
                  },
                }}
              />
              {/* Origen */}
              <CustomInput
                control={control}
                name="movimiento.origen"
                label="Origen"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('origen')
                    : queryMovimiento.data?.origen
                }
                rules={{
                  maxLength: {
                    value: 256,
                    message: 'El origen tiene que ser menor a 256 caracteres',
                  },
                }}
              />
              {/* Destino */}
              <CustomInput
                control={control}
                name="movimiento.destino"
                label="Destino"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('destino')
                    : queryMovimiento.data?.destino
                }
                rules={{
                  maxLength: {
                    value: 256,
                    message: 'El operador tiene que ser menor a 256 caracteres',
                  },
                }}
              />
              {/* Transportista */}
              <CustomInput
                control={control}
                name="movimiento.transportista"
                label="Transportista"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('transportista')
                    : queryMovimiento.data?.transportista
                }
                rules={{
                  required: 'Se requiere de un transportista',
                  maxLength: {
                    value: 256,
                    message:
                      'El transportista tiene que ser menor a 256 caracteres',
                  },
                }}
              />
              {/* Plataforma/Central */}
              <CustomInput
                control={control}
                name="movimiento.plataforma"
                label="Central"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('plataforma')
                    : queryMovimiento.data?.plataforma
                }
                rules={{
                  required: 'Se requiere de una central',
                  maxLength: {
                    value: 32,
                    message: 'La central tiene que ser menor a 32 caracteres',
                  },
                }}
              />
              {/* Estatus de carga */}
              <CustomSelect
                control={control}
                name="movimiento.estatus_carga"
                label="Estatus de carga"
                labelField={'label'}
                valueField={'value'}
                returnFormat={'label'}
                defaultValue={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('estatus_carga')
                    : queryMovimiento.data?.estatus_carga
                }
                data={[
                  { value: 1, label: 'Sin carga' },
                  { value: 2, label: 'Con carga' },
                ]}
                rules={{
                  required: 'Se requiere de un estatus de carga',
                }}
              />
              {/* Usuario externo id */}
              <CustomInput
                control={control}
                name="movimiento.usuario_externo_id"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('usuario_externo_id')
                    : queryMovimiento.data?.usuario_externo_id?.toString()
                }
                tipo={'hidden'}
              />
              {/* Usuario externo nombre */}
              <CustomInput
                control={control}
                name="movimiento.usuario_externo_nombre"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('usuario_externo_nombre')
                    : queryMovimiento.data?.usuario_externo_nombre
                }
                tipo={'hidden'}
              />
              {/* Movimiento externo nombre */}
              <CustomInput
                control={control}
                name="movimiento.movimiento_externo_id"
                value={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('movimiento_externo_id')
                    : queryMovimiento.data?.movimiento_externo_id?.toString()
                }
                tipo={'hidden'}
              />
              {/* Sistema */}
              <CustomSelect
                control={control}
                name="movimiento.sistema_id"
                label="Sistema"
                valueField={'id'}
                labelField={'nombre'}
                data={querySistema.data}
                defaultValue={
                  searchParams.get('movimiento_id') === null
                    ? searchParams.get('sistema_id')
                    : queryMovimiento.data?.sistema?.id
                }
                rules={{
                  required:
                    'Se requiere elegir un sistema de origen de la reacción',
                }}
              />
            </div>
          </div>
        </div>
        <Button
          className={`${classes.boton} mt-3`}
          onClick={handleSubmit((data: any) => {
            mutateReaccion.mutate(
              {
                data: data,
                tipo: 'post',
              },
              {
                onSuccess: () => {
                  //Se redirige en caso de exito
                  navigate('/');
                },
              }
            );
          })}
        >
          Guardar
        </Button>
      </Form>
    </Container>
  );
};
