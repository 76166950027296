//React
import { useCallback, useContext, useEffect, useState } from 'react';
//Componentes externos
import { toast } from 'react-toastify';
//Hooks externos
import { useNavigate, useSearchParams } from 'react-router-dom';
//Contexts
import { AuthContext } from '../context/AuthContext';

/**
 * useSearchParamsValidation
 * @description: Hook que revisa los parámetros enviados por la url y maneja
 * los casos de error y de éxito
 * @date 17/04/2024
 * @returns JSX del Screen.
 */
export const useSearchParamsValidation = () => {
  //Estado para almacenar el resultado de la validación
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
  /* ----------------------------- Hooks ------------------------------------*/
  //Hooks para obtener el context
  const authContext = useContext(AuthContext);
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  //Hook para obtener los parámetros de búsqueda de la url
  const [searchParams] = useSearchParams();
  /* ------------------------------ Funciones -------------------------------*/
  const checkParams = useCallback(async () => {
    if (!authContext.isLoggedIn) {
      if (
        !searchParams.get('usuario_externo_id') ||
        !searchParams.get('usuario_externo_nombre') ||
        !searchParams.get('movimiento_externo_id') ||
        !searchParams.get('sistema_id')
      ) {
        toast.error('Valores de búsqueda inválidos.', {
          toastId: 'invalidSeachParams',
        });
        navigate('/');
        setIsValid(false);
        return;
      }
      setIsValid(true);
    }
  }, [searchParams, authContext.isLoggedIn, navigate]);

  useEffect(() => {
    checkParams();
  }, [checkParams]);

  return isValid;
};
