import React from "react";
import { Spinner } from "react-bootstrap";

export const Loading = () => {
  return (
    <div className="mt-3">
      <Spinner animation="grow" variant="danger" />
    </div>
  );
};
