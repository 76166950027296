//React
import { useCallback, useEffect } from 'react';
//Componentes externos
import Pusher, { Channel } from 'pusher-js';
import { toast } from "react-toastify";
//Hooks externos
import { useQueryClient } from '@tanstack/react-query';
//Interfaces
interface DataWebSocketProps {
  reload?: string;
}
interface UseWebSocketProps {
  channel: string;
  eventName: string;
  condition: boolean;
}
/**
 * useWebSocket
 * @description: Hook para el manejo del webSocket.
 * @date 28/03/2024
 * @param {UseWebSocketProps} { channel, eventName, condition }
 * @returns JSX del Screen.
 */
const useWebSocket = ({ channel, eventName, condition }: UseWebSocketProps) => {
  /* ----------------------------- Hooks ------------------------------------*/
  const queryClient = useQueryClient();
  /* ------------------------------ Funciones -------------------------------*/
  //Conexión pusher
  const pusher = useCallback(
    () =>
      new Pusher('5f2496b201c6a63be030', {
        cluster: 'us2',
      }),
    []
  );
  //Manejo de suscripción
  const subscribe = useCallback(
    (onEvent: (data: DataWebSocketProps) => void) => {
      const subscription = pusher().subscribe(channel);
      subscription.bind(eventName, onEvent);
      return subscription;
    },
    [pusher, channel, eventName]
  );
  //Manejo para eliminar suscripción
  const unsubscribe = useCallback(
    (subscription: Channel) => {
      subscription.unbind(eventName);
      pusher().unsubscribe(channel);
    },
    [pusher, channel, eventName]
  );
  /* --------------------------- useEffect's --------------------------------*/
  //Maneja la suscripción y eliminar la suscripción
  useEffect(() => {
    //Variable que guarda
    const audio = new Audio(require("../sound/notification.wav"));
    let subscription: Channel;
    //Se invalida query y se manda mensaje
    const onEvent = ({reload}: DataWebSocketProps) => {
      if (channel === "reaccion") {
        document.title = "Reacción agregada";
        audio.play();
        toast.success("Reacción agregada al sistema", {
          toastId: reload,
        });
      }
      if (channel === "evento") {
        document.title = "Evento agregado";
        audio.play();
        toast.success("Evento agregado a la reacción", {
          toastId: reload,
        });
      }
      //Se invalida query
      queryClient.invalidateQueries({ queryKey: reload?.split("-") });
    };
    //Dependiendo de la condición se suscribe o no al WebSocket
    if (condition === true) {
      subscription = subscribe(onEvent);
      //Función de limpieza, se elimina la suscripción
      return () => {
        unsubscribe(subscription);
      };
    }
  }, [subscribe, unsubscribe, condition, queryClient, channel]);
};

export default useWebSocket;
