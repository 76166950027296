//Componentes externos
import { Outlet } from "react-router-dom";
//Componentes
import { FooterGeneral } from '../FooterGeneral/FooterGeneral';
import { NavbarGeneral } from '../NavbarGeneral/NavbarGeneral';

/**
 * Wrapper
 * @description: Wrapper de la aplicación
 * @date 10/10/2023.
 * @returns JSX del Screen.
 */
export const Wrapper = () => {

  return (
    <>
      <NavbarGeneral />
      <Outlet/>
      <FooterGeneral />
    </>
  );
};
