//React
import { useContext, useEffect } from 'react';
//Componentes externos
import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
//Componentes
import { Wrapper } from '../../components/Wrapper/Wrapper';
//Contexts
import { AuthContext } from '../../context/AuthContext';
//Screens
import { AvisoPrivacidadScreen } from '../AvisoPrivacidadScreen/AvisoPrivacidadScreen';
import { CausaReaccionListaScreen } from '../CausaReaccionListaScreen/CausaReaccionListaScreen';
import { CausaReaccionCrearScreen } from '../CausaReaccionCrearScreen/CausaReaccionCrearcreen';
import { CausaReaccionEditarScreen } from '../CausaReaccionEditarScreen/CausaReaccionEditarScreen';
import { EventoListaScreen } from '../EventoListaScreen/EventoListaScreen';
import { EventoCrearScreen } from '../EventoCrearScreen/EventoCrearScreen';
import { EventoEditarScreen } from '../EventoEditarScreen/EventoEditarScreen';
import { LoginScreen } from '../LoginScreen/LoginScreen';
import { ReaccionListaScreen } from '../ReaccionListaScreen/ReaccionListaScreen';
import { ReaccionListaInvitadoScreen } from '../ReaccionListaInvitadoScreen/ReaccionListaInvitadoScreen';
import { ReaccionCrearScreen } from '../ReaccionCrearScreen/ReaccionCrearScreen';
import { ReaccionCrearInvitadoScreen } from '../ReaccionCrearInvitadoScreen/ReaccionCrearInvitadoScreen';
import { ReaccionEditarScreen } from '../ReaccionEditarScreen/ReaccionEditarScreen';
import { RolListaScreen } from '../RolListaScreen/RolListaScreen';
import { UsuarioListaScreen } from '../UsuarioListaScreen/UsuarioListaScreen';
import { UsuarioCrearScreen } from '../UsuarioCrearScreen/UsuarioCrearScreen';
import { UsuarioEditarScreen } from '../UsuarioEditarScreen/UsuarioEditarScreen';
//Estilos
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Screen App
 * @description: Es la pantalla que cubre a la App y que tiene definidos
 * los routes de navegación.
 * @date 01/10/2023.
 * @returns JSX del Screen.
 */
const App = () => {
  const queryClient = new QueryClient();
  /* ----------------------------- Hooks ------------------------------------*/
  // Se obtienen los valores del context
  const authContext = useContext(AuthContext);
  /* --------------------------- useEffect's --------------------------------*/
  //Se encarga de verificar si se está volviendo a ver la pestaña
  useEffect(() => {
    //Función para devolver el nombre cuando vuelve a cargar el componente
    const handleVisibilityChange = () => {
      document.title = 'Reacción';
    };
    //Eventos
    document.addEventListener('visibilitychange', handleVisibilityChange);
    //Return
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  //Se encarga de verificar si se está volviendo a ver la pestaña
  useEffect(() => {
    //Función para hacer logout si se es un usuario invitado y se cierra el tab
    const handleTabClose = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      if (authContext.isLoggedIn && authContext.rol === 'Invitado') {
        authContext.logout();
      }
    };
    //Eventos
    window.addEventListener('beforeunload', handleTabClose);
    //Return
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [authContext]);
  /* -------------------------------- return --------------------------------*/
  //Return principal
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Routes>
          {(!authContext.isLoggedIn || authContext.rol === null) && (
            <>
              <Route path="*" element={<Navigate to="/login" />} />
              <Route index path="/login" element={<LoginScreen />} />
              <Route
                path="/aviso_privacidad"
                element={<AvisoPrivacidadScreen />}
              />
              <Route path="reaccion" element={<Wrapper />}>
                <Route index element={<ReaccionListaInvitadoScreen />} />
              </Route>
            </>
          )}
          {authContext.isLoggedIn && authContext.rol === 'Administrador' && (
            <>
              <Route
                path="/aviso_privacidad"
                element={<AvisoPrivacidadScreen />}
              />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/" element={<Wrapper />}>
                <Route index element={<ReaccionListaScreen />} />
              </Route>
              <Route path="evento" element={<Wrapper />}>
                <Route
                  path="lista/:reaccion_id"
                  element={<EventoListaScreen />}
                />
                <Route
                  path="crear/:reaccion_id"
                  element={<EventoCrearScreen />}
                />
                <Route
                  path="editar/:evento_id"
                  element={<EventoEditarScreen />}
                />
              </Route>
              <Route path="reaccion" element={<Wrapper />}>
                <Route index element={<ReaccionListaScreen />} />
                <Route
                  path="lista/:estatus_reaccion_id"
                  element={<ReaccionListaScreen />}
                />
                <Route path="crear" element={<ReaccionCrearScreen />} />
                <Route
                  path="editar/:reaccion_id"
                  element={<ReaccionEditarScreen />}
                />
              </Route>
              <Route path="rol" element={<Wrapper />}>
                <Route index element={<RolListaScreen />} />
              </Route>
              <Route path="usuario" element={<Wrapper />}>
                <Route index element={<UsuarioListaScreen />} />
                <Route path="crear" element={<UsuarioCrearScreen />} />
                <Route
                  path="editar/:usuario_id"
                  element={<UsuarioEditarScreen />}
                />
              </Route>
              <Route path="causa_reaccion" element={<Wrapper />}>
                <Route index element={<CausaReaccionListaScreen />} />
                <Route path="crear" element={<CausaReaccionCrearScreen />} />
                <Route
                  path="editar/:causa_reaccion_id"
                  element={<CausaReaccionEditarScreen />}
                />
              </Route>
            </>
          )}
          {authContext.isLoggedIn && authContext.rol === 'Agente' && (
            <>
              <Route
                path="/aviso_privacidad"
                element={<AvisoPrivacidadScreen />}
              />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/" element={<Wrapper />}>
                <Route index element={<ReaccionListaScreen />} />
              </Route>
              <Route path="evento" element={<Wrapper />}>
                <Route
                  path="lista/:reaccion_id"
                  element={<EventoListaScreen />}
                />
                <Route
                  path="crear/:reaccion_id"
                  element={<EventoCrearScreen />}
                />
                <Route
                  path="editar/:evento_id"
                  element={<EventoEditarScreen />}
                />
              </Route>
              <Route path="reaccion" element={<Wrapper />}>
                <Route index element={<ReaccionListaScreen />} />
                <Route
                  path="lista/:estatus_reaccion_id"
                  element={<ReaccionListaScreen />}
                />
                <Route path="crear" element={<ReaccionCrearScreen />} />
                <Route
                  path="editar/:reaccion_id"
                  element={<ReaccionEditarScreen />}
                />
              </Route>
            </>
          )}
          {authContext.isLoggedIn && authContext.rol === 'Invitado' && (
            <>
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/" element={<Wrapper />}>
                <Route index element={<ReaccionListaInvitadoScreen />} />
              </Route>
              <Route path="evento" element={<Wrapper />}>
                <Route
                  path="lista/:reaccion_id"
                  element={<EventoListaScreen />}
                />
                <Route
                  path="crear/:reaccion_id"
                  element={<EventoCrearScreen />}
                />
                <Route
                  path="editar/:evento_id"
                  element={<EventoEditarScreen />}
                />
              </Route>
              <Route path="reaccion" element={<Wrapper />}>
                <Route index element={<ReaccionListaInvitadoScreen />} />
                <Route path="crear" element={<ReaccionCrearInvitadoScreen />} />
                <Route
                  path="editar/:reaccion_id"
                  element={<ReaccionEditarScreen />}
                />
              </Route>
            </>
          )}
        </Routes>
        {/* Componente para las alertas */}
        <ToastContainer
          position="top-left"
          autoClose={3000}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          draggable={false}
          theme="light"
        />
      </div>
    </QueryClientProvider>
  );
};

export default App;
