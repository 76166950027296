//React
import { useMemo } from 'react';
//Componentes externos
import { Button, Container } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import {
  faSquarePlus,
  faPen,
  faTrash,
  faRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//Componentes
import CustomTable from '../../components/CustomTable/CustomTable';
import { Loading } from '../../components/Loading/Loading';
//Hooks externos
import { useNavigate } from 'react-router-dom';
//Hooks
import { useMutationData } from '../../hooks/useMutationData';
import { useGetData } from '../../hooks/useGetData';
//Interfaces
import { UsuarioInterface } from '../../interfaces/UsuarioInterface';
//Estilo
const classes = require('./UsuarioListaScreen.module.css').default;

/**
 * UsuarioListaScreen
 * @description: Pantalla que muestra la lista de usuarios
 * @date 16/07/2023.
 * @returns JSX del Screen.
 */
export const UsuarioListaScreen = () => {
  /* ----------------------------- Hooks ------------------------------------*/
  //Hook para cambiar el estatus del registro
  const mutateUsuario = useMutationData({
    url: '/usuario',
    reload: ['usuario'],
  });
  //Se encarga de traer la información
  const queryUsuario = useGetData<UsuarioInterface[]>({
    url: '/usuario?includeRol[eq]=true',
    name: ['usuario'],
  });
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  /* ------------------------------ Funciones -------------------------------*/
  const columns = useMemo<ColumnDef<UsuarioInterface>[]>(
    () => [
      {
        header: '#',
        accessorKey: 'id',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'correo',
        header: () => 'Correo',
      },
      {
        accessorKey: 'nombre',
        header: () => 'Nombre',
      },
      {
        accessorKey: 'apellido_paterno',
        header: () => 'Apellido paterno',
      },
      {
        accessorKey: 'apellido_materno',
        header: () => 'Apellido materno',
      },
      {
        id: 'rol',
        header: 'Rol',
        accessorFn: row => row.rol.nombre,
        cell: info => info.getValue(),
      },
      {
        id: 'estatus',
        header: 'Estatus',
        accessorFn: row => (row.estatus === 1 ? 'ACTIVO' : 'ELIMINADO'),
        cell: info => info.getValue(),
      },
      {
        header: 'Acciones',
        cell: info => (
          <fieldset className="btn-group" role="group" aria-label="Ejemplo">
            <Button
              title="Editar usuario"
              className={classes.boton}
              onClick={() =>
                navigate(`/usuario/editar/${info.row.original.id}`)
              }
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {/** Botón para reactivar*/}
            {info.row.original.estatus === 0 && (
              <Button
                title="Reactivar usuario"
                className={classes.boton}
                onClick={() =>
                  mutateUsuario.mutate({
                    id: info.row.original.id,
                    data: { estatus: 1 },
                    tipo: 'patch',
                  })
                }
              >
                <FontAwesomeIcon icon={faRotateLeft} />
              </Button>
            )}
            {/** Botón para eliminar*/}
            {info.row.original.estatus === 1 && (
              <Button
                title="Eliminar usuario"
                className={classes.boton}
                onClick={() =>
                  mutateUsuario.mutate({
                    id: info.row.original.id,
                    data: { estatus: 0 },
                    tipo: 'delete',
                  })
                }
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </fieldset>
        ),
      },
    ],
    [navigate, mutateUsuario]
  );
  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (queryUsuario.isLoading) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container className="p-4">
      {/* Subtítulo */}
      <div className="row mt-3">
        <h2 className={classes.subtitulo}>Usuarios</h2>
      </div>
      <div className={classes.containerTable}>
        <Button
          title="Crear usuario"
          className={classes.boton}
          onClick={() => navigate('/usuario/crear')}
        >
          <FontAwesomeIcon icon={faSquarePlus} /> Crear usuario
        </Button>
      </div>
      {queryUsuario.data && (
        <CustomTable
          {...{
            data: queryUsuario.data,
            columns,
          }}
        />
      )}
    </Container>
  );
};
