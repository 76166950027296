//Hooks externos
import { Controller, ControllerProps } from 'react-hook-form';
//Estilos
const classes = require('./CustomInput.module.css').default;

interface Props {
  control: ControllerProps['control'];
  name: string;
  placeholder?: string;
  value?: string | null;
  tipo?: string;
  label?: string;
  rules?: {};
  isDisabled?: boolean;
  isHidden?: boolean;
}

/**
 * CustomInput
 * @description: Componente para el manejo de inputs.
 * @date 28/03/2024
 * @param {CustomInputProps<T>} props
 * @returns JSX del Screen.
 */
export const CustomInput = ({
  control,
  name,
  placeholder = '',
  value = '',
  tipo = 'text',
  label = '',
  rules = {},
  isDisabled = false,
  isHidden = false,
}: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value === null ? '' : value}
      render={({
        field: { value, onChange, ref, onBlur },
        fieldState: { error },
      }) => (
        <div className={`row ${tipo !== 'hidden' && 'mt-3'}`}>
          {label && tipo !== 'hidden' && (
            <label
              className={`col-3 col-form-label ${classes.labelBackground}`}
              htmlFor={name}
            >
              {label}
            </label>
          )}

          <div
            className={`col-${
              label ? '9 ' + classes.noPadding : '12 ' + classes.noPadding
            }`}
          >
            <input
              ref={ref}
              id={name}
              placeholder={placeholder}
              type={tipo}
              disabled={isDisabled}
              className={`form-control ${
                error === undefined ? 'is-valid' : 'is-invalid'
              } `}
              value={value}
              onChange={text => {
                onChange(text);
              }}
              onBlur={onBlur}
            />
            <div className="invalid-feedback">{error?.message || 'Error'}</div>
          </div>
        </div>
      )}
    />
  );
};
