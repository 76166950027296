//Estilos
const classes = require("./FooterGeneral.module.css").default;

/**
 * FooterGeneral
 * @description: Es la pantalla que contiene el nav de la aplicación.
 * @date 10/06/2023.
 * @returns JSX del Screen.
 */
export const FooterGeneral = () => {
  return (
    <footer
      className={`${classes.footer} d-flex flex-wrap justify-content-between align-items-center px-5 py-3`}
    >
      <div className="col-md-4 d-flex align-items-center">
        <a
          className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1"
          href="https://skyangel.com.mx/"
        >
          <img
            src={require("../../img/skyangel.png")}
            width="50px"
            height="50px"
            alt=""
          />
        </a>
        <span className={`${classes.span} mb-3 mb-md-0`}>
          Copyright © 2023 Skyangel.
        </span>
      </div>
      <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
        <li className="ms-3">
          <span className={`${classes.span} mb-3 mb-md-0`}>
            Esta es la página electrónica del Sistema de Control de Reacciones
            de la empresa SkyAngel.
          </span>
        </li>
      </ul>
    </footer>
  );
};
