//React
import { useContext } from "react";
//Componentes externos
import axios from "axios";
import { toast } from "react-toastify";
//Hooks externos
import { useMutation, useQueryClient } from "@tanstack/react-query";
//Contexts
import { AuthContext } from "../context/AuthContext";
//API
import { API } from "../global/global";
//Props
interface Props {
  url: string;
  reload?: any[];
}
interface PropsMutation {
  id?: number | string;
  data?: any;
  tipo: string;
}

export const useMutationData = ({ url, reload }: Props) => {
  const authContext = useContext(AuthContext);
  const queryClient = useQueryClient();

  const mutationData = ({ id, data, tipo }: PropsMutation) => {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authContext.token,
      },
    };
    switch (tipo) {
      case "patch":
        if (id) {
          return axios.patch(API + url + `/${id}`, data, options);
        }
        return axios.patch(API + url, data, options);
      case "put":
        if (id) {
          return axios.put(API + url + `/${id}`, data, options);
        }
        return axios.put(API + url, data, options);
      case "delete":
        return axios.delete(API + url + `/${id}`, options);
      case "post":
      default:
        if (id) {
          return axios.post(API + url + `/${id}`, data, options);
        }
        return axios.post(API + url, data, options);
    }
  };

  return useMutation({
    mutationFn: ({ id, data, tipo }: PropsMutation) =>
      mutationData({ id, data, tipo }),
    onSuccess: (data) => {
      toast.success(data?.data?.message || "Error", {
        toastId: data?.data?.message,
      });

      return queryClient.invalidateQueries({ queryKey: reload });
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message || "Error", {
        toastId: err?.response?.data?.message,
      });
    },
  });
};
