//React
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
//Estilos
const classes = require("./TimelineCard.module.css").default;

type Props = {
  id: number;
  titulo: string;
  descripcion: string;
  icono: ReactElement;
};

const TimelineCard = ({ id, titulo, descripcion, icono }: Props) => {
  /* ----------------------------- Hooks ------------------------------------*/
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  /* -------------------------------- return --------------------------------*/
  return (
    <div
      className="col-12 col-md-6 col-lg-4"
      style={{ cursor: "pointer" }}
      onClick={() => navigate(`/evento/editar/${id}`)}
    >
      <div
        className={`${classes.singleTimelineContent} d-flex wow fadeInLeft`}
        data-wow-delay="0.3s"
        style={{
          visibility: "visible",
          animationDelay: "0.3s",
          animationName: "fadeInLeft",
        }}
      >
        <div className={`${classes.timelineIcon}`}>{icono}</div>
        <div className={`${classes.timelineText}`}>
          <h6>{titulo}</h6>
          <p>{descripcion}</p>
        </div>
      </div>
    </div>
  );
};

export default TimelineCard;
